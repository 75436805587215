export const environment = {
  "B2CClientId": "2502f04f-b500-4f11-9c09-f3e39b2007ef",
  "awsBaseUrl": "https://restoreops-api-dev.duke-energy.app/restoreops",
  "azure_client_id": "2502f04f-b500-4f11-9c09-f3e39b2007ef",
  "azure_redirect_uri": "https://restoreops-dev.duke-energy.app/welcome",
  "azure_tenant": "7be91832-8fc6-45b8-ba19-723531347970",
  "envName": "dev",
  "msalConfig": {
    "scopes": [
      "https://DukeEnergyQAB2C.onmicrosoft.com/dab2capi/user_impersonation"
    ]
  },
  "production": "false",
  "tenantPolicyConfig": {
    "B2CEndPoint": "https://dukeenergyqab2c.b2clogin.com/dukeenergyqab2c.onmicrosoft.com/",
    "employeeSignInPolicy": "B2C_1_DA_Employee_Sign_Up_Sign_In",
    "forgotPasswordPolicy": "B2C_1_Password_Reset_DA",
    "logOutRedirectUri": "https://restoreops-dev.duke-energy.app/welcome",
    "redirectUri": "https://restoreops-dev.duke-energy.app/welcome",
    "signInPolicy": "B2C_1_SignIn_DA",
    "signUpPolicy": "B2C_1_SignUp_DA"
  },
  "vsac_url": "https://outage-maps-internal-web-dev.appc-dlbcorp-eaqu1qa.duke-energy.com"
}
